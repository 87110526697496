var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12 mt-5", attrs: { align: "center" } },
      [
        _c(
          "v-card",
          { attrs: { flat: "", shaped: "" } },
          [
            _c(
              "v-toolbar",
              { attrs: { flat: "" } },
              [
                _c("v-toolbar-title", [_vm._v("ÖDEVLERİM")]),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.homeworks.length > 0,
                        expression: "homeworks.length > 0",
                      },
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        value: _vm.hoverText,
                        expression: "hoverText",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "text-muted ml-2",
                  },
                  [_vm._v(" " + _vm._s(_vm.titleMuted))]
                ),
                _c("v-spacer"),
                _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "300px" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  { staticClass: "mx-2", attrs: { small: "" } },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v(" FİLTRE")]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.filterDialog,
                      callback: function ($$v) {
                        _vm.filterDialog = $$v
                      },
                      expression: "filterDialog",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-toolbar",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-row",
                              { staticClass: "justify-content-between" },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "col-4" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        on: {
                                          click: function ($event) {
                                            _vm.filterDialog = false
                                          },
                                        },
                                      },
                                      [_vm._v("mdi-close")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "col-4 d-flex justify-content-center",
                                  },
                                  [_c("h5", [_vm._v("FİLTRELE")])]
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "col-4 text-right" },
                                  [
                                    _c(
                                      "v-icon",
                                      { on: { click: _vm.cleanFilter } },
                                      [_vm._v("mdi-refresh")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-dialog",
                              {
                                ref: "dialog",
                                attrs: {
                                  "return-value": _vm.dates,
                                  persistent: "",
                                  width: "290px",
                                },
                                on: {
                                  "update:returnValue": function ($event) {
                                    _vm.dates = $event
                                  },
                                  "update:return-value": function ($event) {
                                    _vm.dates = $event
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mt-3",
                                                attrs: {
                                                  "prepend-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                  label: "Tarih Aralığı",
                                                  "hide-details": "",
                                                  "append-icon":
                                                    "mdi-close-circle",
                                                },
                                                on: {
                                                  "click:append": function (
                                                    $event
                                                  ) {
                                                    _vm.dates = []
                                                    _vm.getHomeworksFromApi()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.dates,
                                                  callback: function ($$v) {
                                                    _vm.dates = $$v
                                                  },
                                                  expression: "dates",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.dateDialog,
                                  callback: function ($$v) {
                                    _vm.dateDialog = $$v
                                  },
                                  expression: "dateDialog",
                                },
                              },
                              [
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-date-picker",
                                      {
                                        attrs: {
                                          "first-day-of-week": "1",
                                          scrollable: "",
                                          range: "",
                                          color: "green lighten-1",
                                          "header-color": "primary",
                                          min: "2021-09-13",
                                        },
                                        model: {
                                          value: _vm.dates,
                                          callback: function ($$v) {
                                            _vm.dates = $$v
                                          },
                                          expression: "dates",
                                        },
                                      },
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              text: "",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.dateDialog = false
                                              },
                                            },
                                          },
                                          [_vm._v(" İPTAL")]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              text: "",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$refs.dialog.save(
                                                  _vm.dates
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" ARA")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "mx-3",
                                        model: {
                                          value: _vm.orderByCreatedDate,
                                          callback: function ($$v) {
                                            _vm.orderByCreatedDate = $$v
                                          },
                                          expression: "orderByCreatedDate",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: {
                                            label: "Son Kontrol Tarihine Göre",
                                            value: false,
                                          },
                                        }),
                                        _c("v-radio", {
                                          attrs: {
                                            label: "Ödev Yapma Tarihine Göre",
                                            value: true,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-autocomplete", {
                              attrs: {
                                "item-text": (item) => item.Name,
                                "item-value": (item) => item.Id,
                                items: _vm.lessons,
                                label: "Ders",
                                "single-line": "",
                                "hide-details": "",
                                clearable: "",
                              },
                              on: { change: _vm.getSourcesFromApi },
                              model: {
                                value: _vm.lessonId,
                                callback: function ($$v) {
                                  _vm.lessonId = $$v
                                },
                                expression: "lessonId",
                              },
                            }),
                            _c("v-autocomplete", {
                              attrs: {
                                "item-text": (item) => item.Name,
                                "item-value": (item) => item.Id,
                                items: _vm.sources,
                                "single-line": "",
                                "hide-details": "",
                                clearable: "",
                                disabled: _vm.lessonId <= 0,
                                label:
                                  _vm.lessonId > 0
                                    ? "Kaynak"
                                    : "Kaynak (ders seçiniz)",
                              },
                              model: {
                                value: _vm.sourceId,
                                callback: function ($$v) {
                                  _vm.sourceId = $$v
                                },
                                expression: "sourceId",
                              },
                            }),
                            _c("v-switch", {
                              attrs: {
                                "hide-details": "",
                                inset: "",
                                small: "",
                                flat: "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _vm.isCompleted
                                        ? _c("span", [
                                            _vm._v("Yapılmayanlar/"),
                                            _c("b", [_vm._v("Yapılanlar")]),
                                          ])
                                        : _vm._e(),
                                      !_vm.isCompleted
                                        ? _c("span", [
                                            _c("b", [_vm._v("Yapılmayanlar")]),
                                            _vm._v("/Yapılanlar"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.isCompleted,
                                callback: function ($$v) {
                                  _vm.isCompleted = $$v
                                },
                                expression: "isCompleted",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "justify-content-center" },
                          [
                            _c("v-btn", { on: { click: _vm.addFilter } }, [
                              _vm._v(" UYGULA"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("v-card-text", [
              _vm.homeworks.length > 0
                ? _c(
                    "div",
                    { staticClass: "pt-5" },
                    [
                      _vm._l(
                        _vm.homeworks.slice(0, _vm.hwCount),
                        function (item) {
                          return _c(
                            "div",
                            {
                              key: item.Id,
                              staticClass: "d-flex align-items-center mb-8",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-grow-1 text-left" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "text-gray-800 text-hover-primary fw-bolder fs-6 mt-1",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getSourceTestAnswersFromApi(
                                            item.SourceTestId,
                                            item.HomeworkId,
                                            item.IsLocked
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.LessonName) +
                                          " - " +
                                          _vm._s(item.SourceName) +
                                          " "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " Bölüm: " +
                                          _vm._s(item.SourceChapterName) +
                                          " "
                                      ),
                                      _c("br"),
                                    ]
                                  ),
                                  _c(
                                    "b-badge",
                                    { attrs: { pill: "", variant: "primary" } },
                                    [
                                      _vm._v(
                                        "Sayfa: " +
                                          _vm._s(item.StartPage) +
                                          " - " +
                                          _vm._s(item.EndPage) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm._v("   "),
                                  _c(
                                    "b-badge",
                                    { attrs: { pill: "", variant: "success" } },
                                    [_vm._v(_vm._s(item.SourceTestName))]
                                  ),
                                  _vm._v("   "),
                                  _c(
                                    "b-badge",
                                    { attrs: { pill: "", variant: "danger" } },
                                    [
                                      _vm._v(
                                        _vm._s(item.QuestionCount) + " Soru"
                                      ),
                                    ]
                                  ),
                                  _vm._v("   "),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "label label-inline",
                                      attrs: {
                                        id: "popover-target-" + item.Id,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.Achievements.length) +
                                          " Kazanım "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "popover-target-" + item.Id,
                                        triggers: "focus",
                                        placement: "right",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "title",
                                            fn: function () {
                                              return [_vm._v("Kazanımlar")]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    _vm._l(
                                      item.Achievements,
                                      function (i, index) {
                                        return _c("div", { key: index }, [
                                          _vm._v(
                                            " " + _vm._s(i.ChildAchievementName)
                                          ),
                                          _c("br"),
                                          _c("hr"),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-muted fw-bold d-block mt-1",
                                    },
                                    [
                                      _vm._v(
                                        "Son Tarih: " +
                                          _vm._s(
                                            _vm._f("moment")(item.EndDate)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  item.SourceTestResult != null &&
                                  item.SourceTestResult.CorrectCount == 0 &&
                                  item.SourceTestResult.WrongCount == 0
                                    ? _c(
                                        "b-progress",
                                        {
                                          staticClass: "mt-2",
                                          attrs: {
                                            max: item.QuestionCount,
                                            variant: "info",
                                            "show-progress": "",
                                          },
                                        },
                                        [
                                          _c("b-progress-bar", {
                                            attrs: {
                                              value: item.AnsweredQuestionCount,
                                              label: `${item.progressValue.toFixed(
                                                0
                                              )}%`,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : item.SourceTestResult != null
                                    ? _c(
                                        "b-progress",
                                        {
                                          directives: [
                                            {
                                              name: "b-popover",
                                              rawName: "v-b-popover.hover",
                                              value: `${item.SourceTestResult.CorrectCount} D / ${item.SourceTestResult.WrongCount} Y / ${item.SourceTestResult.EmptyCount} B`,
                                              expression:
                                                "\n                  `${item.SourceTestResult.CorrectCount} D / ${item.SourceTestResult.WrongCount} Y / ${item.SourceTestResult.EmptyCount} B`\n                ",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          staticClass: "mt-3",
                                          attrs: {
                                            max: item.QuestionCount,
                                            "show-value": "",
                                          },
                                        },
                                        [
                                          _c("b-progress-bar", {
                                            attrs: {
                                              value:
                                                item.SourceTestResult
                                                  .CorrectCount,
                                              variant: "success",
                                            },
                                          }),
                                          _c("b-progress-bar", {
                                            attrs: {
                                              value:
                                                item.SourceTestResult
                                                  .WrongCount,
                                              variant: "danger",
                                            },
                                          }),
                                          _c("b-progress-bar", {
                                            attrs: {
                                              value:
                                                item.SourceTestResult
                                                  .EmptyCount,
                                              variant: "secondary",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getSourceTestAnswersFromApi(
                                        item.SourceTestId,
                                        item.HomeworkId,
                                        item.IsLocked
                                      )
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v(" mdi-arrow-right")])],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _vm.homeworks.length > _vm.hwCount
                        ? _c(
                            "b-button",
                            {
                              attrs: { size: "sm" },
                              on: {
                                click: function ($event) {
                                  _vm.hwCount = _vm.hwCount + 5
                                },
                              },
                            },
                            [_vm._v("Devamını Yükle ")]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _c("div", { staticClass: "mb-5" }, [
                    _c("span", [_vm._v("ÖDEVİNİZ BULUNMAMAKTADIR")]),
                  ]),
            ]),
          ],
          1
        ),
        _c(
          "v-dialog",
          {
            attrs: { "max-width": "360px" },
            model: {
              value: _vm.sourceTestDialog,
              callback: function ($$v) {
                _vm.sourceTestDialog = $$v
              },
              expression: "sourceTestDialog",
            },
          },
          [
            _c(
              "b-card",
              { staticStyle: { "max-width": "360px" } },
              [
                _c("b-card-title", { staticClass: "text-center" }, [
                  _c("span", { staticClass: "font-weight-normal" }, [
                    _vm._v(_vm._s(_vm.sourceTest.SourceName)),
                  ]),
                  _c("hr"),
                  _c("span", { staticClass: "font-weight-normal" }, [
                    _vm._v(_vm._s(_vm.sourceTest.SourceChapterName) + " "),
                  ]),
                  _c("hr"),
                  _c("span", { staticClass: "font-weight-normal" }, [
                    _vm._v(_vm._s(_vm.sourceTest.Name)),
                  ]),
                ]),
                _vm._l(_vm.homeworkAnswers, function (item, index) {
                  return _c("div", { key: index }, [
                    _c("div", { staticClass: "mt-3 d-flex" }, [
                      _c("div", { staticClass: "text-center mt-2" }, [
                        _vm._v(_vm._s(item.QuestionNumber.toString()) + ")"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-center flex-grow-1" },
                        [
                          _c(
                            "b-form-radio-group",
                            {
                              attrs: {
                                size: "md",
                                name: "radio-btn-outline",
                                buttons: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.saveTestAnswerToList(item)
                                },
                              },
                              model: {
                                value: item.TestOptionTypeId,
                                callback: function ($$v) {
                                  _vm.$set(item, "TestOptionTypeId", $$v)
                                },
                                expression: "item.TestOptionTypeId",
                              },
                            },
                            _vm._l(_vm.testOptionTypes, function (option) {
                              return _c(
                                "b-form-radio",
                                {
                                  key: option.Id,
                                  attrs: {
                                    value: option.Id,
                                    name: option.Name,
                                    disabled:
                                      option.Id != item.TestOptionTypeId
                                        ? option.disabled
                                        : false,
                                    "button-variant": item.color
                                      ? item.color
                                      : "outline-primary",
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.Name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                }),
                _vm.correctCount === 0 && _vm.wrongCount === 0
                  ? _c(
                      "b-progress",
                      {
                        staticClass: "mt-2",
                        attrs: {
                          max: _vm.progressMax,
                          variant: "primary",
                          "show-progress": "",
                        },
                      },
                      [
                        _c("b-progress-bar", {
                          attrs: {
                            value: _vm.answeredQuestionCount,
                            label: `${(
                              (_vm.answeredQuestionCount / _vm.progressMax) *
                              100
                            ).toFixed(0)}%`,
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "b-progress",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover",
                            value:
                              _vm.correctCount +
                              " D / " +
                              _vm.wrongCount +
                              " Y / " +
                              _vm.emptyCount +
                              " B",
                            expression:
                              "correctCount + ' D / ' + wrongCount + ' Y / ' + emptyCount + ' B'",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "mt-3",
                        attrs: { max: _vm.progressMax, "show-value": "" },
                      },
                      [
                        _c("b-progress-bar", {
                          attrs: {
                            value: _vm.correctCount,
                            variant: "success",
                          },
                        }),
                        _c("b-progress-bar", {
                          attrs: { value: _vm.wrongCount, variant: "danger" },
                        }),
                        _c("b-progress-bar", {
                          attrs: {
                            value: _vm.emptyCount,
                            variant: "secondary",
                          },
                        }),
                      ],
                      1
                    ),
                !_vm.sourceTest.isLocked
                  ? _c(
                      "div",
                      { staticClass: "text-center m-3" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-3",
                            attrs: { variant: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.saveTestAnswersBulk(
                                  _vm.homeworkAnswers[0].SourceTestId,
                                  _vm.homeworkAnswers[0].HomeworkId
                                )
                              },
                            },
                          },
                          [_vm._v("KAYDET ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }