<template>
  <div class="row">
    <div class="col-12 mt-5" align="center">
      <v-card flat shaped>
        <v-toolbar flat>
          <v-toolbar-title>ÖDEVLERİM</v-toolbar-title>
          <span v-show="homeworks.length > 0" v-b-tooltip.hover="hoverText" class="text-muted ml-2">
            {{ titleMuted }}</span
          >
          <v-spacer></v-spacer>
          <v-dialog v-model="filterDialog" max-width="300px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small class="mx-2" v-bind="attrs" v-on="on"> FİLTRE</v-btn>
            </template>
            <v-card>
              <v-toolbar flat>
                <v-row class="justify-content-between">
                  <v-col class="col-4">
                    <v-icon @click="filterDialog = false">mdi-close</v-icon>
                  </v-col>
                  <v-col class="col-4 d-flex justify-content-center"><h5>FİLTRELE</h5></v-col>
                  <v-col class="col-4 text-right">
                    <v-icon @click="cleanFilter">mdi-refresh</v-icon>
                  </v-col>
                </v-row>
              </v-toolbar>
              <v-card-text>
                <v-dialog
                    ref="dialog"
                    v-model="dateDialog"
                    :return-value.sync="dates"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dates"
                        prepend-icon="mdi-calendar"
                        readonly
                        label="Tarih Aralığı"
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        @click:append="
                        dates = []
                        getHomeworksFromApi()
                      "
                        append-icon="mdi-close-circle"
                        class="mt-3"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="dates"
                        scrollable
                        range
                        color="green lighten-1"
                        header-color="primary"
                        min="2021-09-13"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dateDialog = false"> İPTAL</v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(dates)"> ARA</v-btn>
                    </v-date-picker>
                    <v-radio-group class="mx-3" v-model="orderByCreatedDate">
                      <v-radio label="Son Kontrol Tarihine Göre" :value="false"></v-radio>
                      <v-radio label="Ödev Yapma Tarihine Göre" :value="true"></v-radio>
                    </v-radio-group>
                  </v-card>
                </v-dialog>
                <v-autocomplete
                    v-model="lessonId"
                    :item-text="(item) => item.Name"
                    :item-value="(item) => item.Id"
                    :items="lessons"
                    label="Ders"
                    single-line
                    hide-details
                    clearable
                    @change="getSourcesFromApi"
                >
                </v-autocomplete>
                <v-autocomplete
                    v-model="sourceId"
                    :item-text="(item) => item.Name"
                    :item-value="(item) => item.Id"
                    :items="sources"
                    single-line
                    hide-details
                    clearable
                    :disabled="lessonId <= 0"
                    :label="lessonId > 0 ? 'Kaynak' : 'Kaynak (ders seçiniz)'"
                >
                </v-autocomplete>
                <v-switch v-model="isCompleted" hide-details inset small flat>
                  <template v-slot:label>
                    <span v-if="isCompleted">Yapılmayanlar/<b>Yapılanlar</b></span>
                    <span v-if="!isCompleted"><b>Yapılmayanlar</b>/Yapılanlar</span>
                  </template>
                </v-switch>
              </v-card-text>
              <v-card-actions class="justify-content-center">
                <v-btn @click="addFilter"> UYGULA</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-card-text>
          <div class="pt-5" v-if="homeworks.length > 0">
            <div
                class="d-flex align-items-center mb-8"
                v-for="item in homeworks.slice(0, hwCount)"
                :key="item.Id"
            >
              <div class="flex-grow-1 text-left">
                <a
                    href="#"
                    @click="
                    getSourceTestAnswersFromApi(item.SourceTestId, item.HomeworkId, item.IsLocked)
                  "
                    class="text-gray-800 text-hover-primary fw-bolder fs-6 mt-1"
                >{{ item.LessonName }} - {{ item.SourceName }} <br/>
                  Bölüm: {{ item.SourceChapterName }} <br/>
                </a>
                <b-badge pill variant="primary"
                >Sayfa: {{ item.StartPage }} - {{ item.EndPage }}
                </b-badge>
                &nbsp;
                <b-badge pill variant="success">{{ item.SourceTestName }}</b-badge> &nbsp;
                <b-badge pill variant="danger">{{ item.QuestionCount }} Soru</b-badge> &nbsp;
                <b-button class="label label-inline" :id="'popover-target-' + item.Id">
                  {{ item.Achievements.length }} Kazanım
                </b-button>

                <b-popover :target="'popover-target-' + item.Id" triggers="focus" placement="right">
                  <template #title>Kazanımlar</template>
                  <div v-for="(i, index) in item.Achievements" :key="index">
                    {{ i.ChildAchievementName }}<br/>
                    <hr/>
                  </div>
                </b-popover>
                <span class="text-muted fw-bold d-block mt-1"
                >Son Tarih: {{ item.EndDate | moment }}
                </span>

                <b-progress
                    v-if=" item.SourceTestResult != null &&
                    item.SourceTestResult.CorrectCount == 0 && item.SourceTestResult.WrongCount == 0
                  "
                    :max="item.QuestionCount"
                    variant="info"
                    show-progress
                    class="mt-2"
                >
                  <b-progress-bar
                      :value="item.AnsweredQuestionCount"
                      :label="`${item.progressValue.toFixed(0)}%`"
                  ></b-progress-bar>
                </b-progress>
                <b-progress
                    v-else-if="item.SourceTestResult != null"
                    v-b-popover.hover="
                    `${item.SourceTestResult.CorrectCount} D / ${item.SourceTestResult.WrongCount} Y / ${item.SourceTestResult.EmptyCount} B`
                  "
                    class="mt-3"
                    :max="item.QuestionCount"
                    show-value
                >
                  <b-progress-bar
                      :value="item.SourceTestResult.CorrectCount"
                      variant="success"
                  ></b-progress-bar>
                  <b-progress-bar
                      :value="item.SourceTestResult.WrongCount"
                      variant="danger"
                  ></b-progress-bar>
                  <b-progress-bar
                      :value="item.SourceTestResult.EmptyCount"
                      variant="secondary"
                  ></b-progress-bar>
                </b-progress>
              </div>
              <v-btn
                  @click="
                  getSourceTestAnswersFromApi(item.SourceTestId, item.HomeworkId, item.IsLocked)
                "
                  icon
              >
                <v-icon> mdi-arrow-right</v-icon>
              </v-btn
              >
            </div>
            <b-button v-if="homeworks.length > hwCount" size="sm" @click="hwCount = hwCount + 5"
            >Devamını Yükle
            </b-button
            >
          </div>
          <div v-else class="mb-5"><span>ÖDEVİNİZ BULUNMAMAKTADIR</span></div>
        </v-card-text>
      </v-card>
      <v-dialog v-model="sourceTestDialog" max-width="360px">
        <b-card style="max-width: 360px">
          <b-card-title class="text-center">
            <span class="font-weight-normal">{{ sourceTest.SourceName }}</span>
            <hr/>
            <span class="font-weight-normal">{{ sourceTest.SourceChapterName }} </span>
            <hr/>
            <span class="font-weight-normal">{{ sourceTest.Name }}</span>
          </b-card-title>
          <div v-for="(item, index) in homeworkAnswers" :key="index">
            <div class="mt-3 d-flex">
              <div class="text-center mt-2">{{ item.QuestionNumber.toString() }})</div>
              <div class="text-center flex-grow-1">
                <b-form-radio-group
                    v-model="item.TestOptionTypeId"
                    size="md"
                    name="radio-btn-outline"
                    buttons
                    @change="saveTestAnswerToList(item)"
                >
                  <b-form-radio
                      v-for="option in testOptionTypes"
                      :key="option.Id"
                      :value="option.Id"
                      :name="option.Name"
                      :disabled="option.Id != item.TestOptionTypeId ? option.disabled : false"
                      :button-variant="item.color ? item.color : 'outline-primary'"
                  >
                    {{ option.Name }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </div>
          <b-progress
              v-if="correctCount === 0 && wrongCount === 0"
              :max="progressMax"
              variant="primary"
              show-progress
              class="mt-2"
          >
            <b-progress-bar
                :value="answeredQuestionCount"
                :label="`${((answeredQuestionCount / progressMax) * 100).toFixed(0)}%`"
            ></b-progress-bar>
          </b-progress>
          <b-progress
              v-else
              v-b-popover.hover="correctCount + ' D / ' + wrongCount + ' Y / ' + emptyCount + ' B'"
              class="mt-3"
              :max="progressMax"
              show-value
          >
            <b-progress-bar :value="correctCount" variant="success"></b-progress-bar>
            <b-progress-bar :value="wrongCount" variant="danger"></b-progress-bar>
            <b-progress-bar :value="emptyCount" variant="secondary"></b-progress-bar>
          </b-progress>
          <div class="text-center m-3" v-if="!sourceTest.isLocked">
            <b-button
                variant="primary"
                class="mt-3"
                @click="
                saveTestAnswersBulk(homeworkAnswers[0].SourceTestId, homeworkAnswers[0].HomeworkId)
              "
            >KAYDET
            </b-button
            >
          </div>
        </b-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'
import Swal from 'sweetalert2'

export default {
  props: ['orderByEndDate'],
  name: 'odevler-sayfasi',
  data() {
    return {
      hwCount: 5,
      homeworks: [],
      isCompleted: false,
      lessonId: 0,
      lessons: [],
      sourceId: 0,
      sources: [],
      filterDialog: false,
      dateDialog: false,
      answerCount: 0,
      testOptionTypes: [],
      homeworkAnswers: [],
      sourceTest: {},
      answersBulk: [],
      sourceTestAnswers: [],
      sourceTestDialog: false,
      orderByCreatedDate: false,
      dates: [new Date(2021, 8, 13, 6, 0, 0).toISOString().slice(0, 10), null]
    }
  },
  computed: {
    titleMuted() {
      if (this.hwCount <= this.homeworks.length) {
        return `${this.hwCount} / ${this.homeworks.length}`
      } else {
        return `${this.homeworks.length} / ${this.homeworks.length}`
      }
    },
    hoverText() {
      if (this.homeworks.length > 0) {
        var totalQuestionCount = 0
        for (let index = 0; index < this.homeworks.length; index++) {
          const element = this.homeworks[index]
          if (element != null) {
            totalQuestionCount = totalQuestionCount += element.QuestionCount
          }
        }

        var showedQuestionCount = 0
        for (let index = 0; index <= this.hwCount; index++) {
          if (this.homeworks.length < this.hwCount) {
            return
          } else {
            const element = this.homeworks[index]
            if (element != null) {
              showedQuestionCount = showedQuestionCount += element.QuestionCount
            }
          }
        }
        return `Soru Sayısı: ${showedQuestionCount}/${totalQuestionCount}`
      } else {
        return 'Ödev Yok'
      }
    },
    progressMax() {
      return this.homeworkAnswers.length
    },
    correctCount() {
      return this.homeworkAnswers.filter((s) => s.color == 'outline-success').length
    },
    wrongCount() {
      return this.homeworkAnswers.filter((s) => s.color == 'outline-danger').length
    },
    emptyCount() {
      return this.homeworkAnswers.filter((s) => s.color == null || s.color.length == 0).length
    },
    answeredQuestionCount() {
      return this.homeworkAnswers.filter((s) => s.TestOptionTypeId != null).length
    }
  },
  watch: {
    lessonId: function () {
      if (this.lessonId == 0 || this.lessonId == null) {
        this.sourceId = 0
      }
    },
    orderByCreatedDate(val) {
      if (val) {
        this.isCompleted = true
      }
    },
    sourceTestDialog() {
      this.getHomeworksFromApi()
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('dddd, Do MMMM YYYY') : ''
    }
  },
  created() {
    this.getTestOptionTypesFromApi()
    this.getLessonsFromApi()
  },
  mounted() {
    this.getHomeworksFromApi()
  },
  methods: {
    moment: function () {
      return moment()
    },
    getHomeworksFromApi() {
      if (this.dates[0] && this.dates[1]) {
        var g1 = new Date(this.dates[0])
        var g2 = new Date(this.dates[1])
        if (g2.getTime() < g1.getTime()) {
          var tmpDate = this.dates[0]
          this.dates[0] = this.dates[1]
          this.dates[1] = tmpDate
        }
      }

      ApiService.setHeader()
      ApiService.post('api/Homework/HomeworkSourceTest', {
        StartDate: this.dates[0],
        EndDate: this.dates[1],
        LessonId: this.lessonId,
        SourceId: this.sourceId,
        IsCompleted: this.isCompleted,
        IsNotCompleted: !this.isCompleted,
        OrderByCreatedDate: this.orderByCreatedDate,
        WithoutManualHomeworks: true
      })
          .then((data) => {
            this.homeworks = data.data

            for (let index = 0; index < this.homeworks.length; index++) {
              const element = this.homeworks[index]
              element.progressValue = (element.AnsweredQuestionCount / element.QuestionCount) * 100
            }
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    getLessonsFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Lesson', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {IsStudent: true}
      })
          .then((data) => {
            this.lessons = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    getSourcesFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Source', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {LessonId: this.lessonId, IsStudent: true}
      })
          .then((data) => {
            this.sources = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    addFilter() {
      this.getHomeworksFromApi()
      this.filterDialog = false
    },
    cleanFilter() {
      this.lessonId = 0
      this.sourceId = 0
      this.isCompleted = false
    },
    getTestOptionTypesFromApi() {
      ApiService.setHeader()
      ApiService.post('api/TestOptionType', {
        sortBy: ['DisplayOrder'],
        descending: [false],
        rowsPerPage: -1,
        page: 1
      })
          .then((data) => {
            this.testOptionTypes = data.data.Results
            this.testOptionTypes.push({
              DisplayOrder: 99,
              Name: 'Boş',
              Id: 0
            })
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    async getSourceTestAnswersFromApi(sourceTestId, homeworkId, isLocked) {
      ApiService.setHeader()
      try {
        let answerData = await ApiService.post('api/SourceTestAnswer', {
          sortBy: ['QuestionNumber'],
          descending: [false],
          rowsPerPage: -1,
          page: 1,
          query: {SourceTestId: sourceTestId}
        })

        this.sourceTestAnswers = answerData.data.Results
      } catch (response) {
        ApiService.showError(response.response)
        return
      }

      this.homeworkAnswers = []
      ApiService.setHeader()
      try {
        let data = await ApiService.get('api/SourceTest/' + sourceTestId)
        this.sourceTest = data.data
        this.answerCount = data.data.QuestionCount

        if (isLocked) {
          this.sourceTest.isLocked = true
          for (let index = 0; index < this.testOptionTypes.length; index++) {
            const element = this.testOptionTypes[index]
            element.disabled = true
          }
        } else {
          for (let index = 0; index < this.testOptionTypes.length; index++) {
            const element = this.testOptionTypes[index]
            element.disabled = false
          }
        }
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
      try {
        let data2 = await ApiService.post('api/HomeworkAnswer', {
          sortBy: ['QuestionNumber'],
          descending: [false],
          rowsPerPage: -1,
          page: 1,
          query: {
            SourceTestId: sourceTestId,
            HomeworkId: homeworkId
          }
        })

        this.homeworkAnswers = data2.data.Results
        for (let index = 1; index <= this.answerCount; index++) {
          if (this.homeworkAnswers.filter((s) => s.QuestionNumber == index).length == 0) {
            var element = {
              QuestionNumber: index,
              HomeworkId: homeworkId,
              Id: 0,
              SourceTestId: sourceTestId
            }
            this.homeworkAnswers.push(element)
            this.homeworkAnswers = this.homeworkAnswers.sort(function (a, b) {
              return a.QuestionNumber - b.QuestionNumber
            })
          } else if (this.sourceTestAnswers.length > 0) {
            var isCorrectAnswer =
                this.sourceTestAnswers.filter(
                    (s) =>
                        s.QuestionNumber == this.homeworkAnswers[index - 1].QuestionNumber &&
                        s.TestOptionTypeIds.includes(this.homeworkAnswers[index - 1].TestOptionTypeId)
                ).length == 1
            if (isCorrectAnswer) {
              this.homeworkAnswers.filter(
                  (s) => s.QuestionNumber == this.homeworkAnswers[index - 1].QuestionNumber
              )[0].color = 'outline-success'
            } else {
              this.homeworkAnswers.filter(
                  (s) => s.QuestionNumber == this.homeworkAnswers[index - 1].QuestionNumber
              )[0].color = 'outline-danger'
            }
          }
        }
        this.sourceTestDialog = true
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
    },

    saveTestAnswerToList(item) {
      for (var i = 0; i < this.answersBulk.length; i++) {
        if (this.answersBulk[i].QuestionNumber == item.QuestionNumber) {
          this.answersBulk.splice(i, 1)
          break
        }
      }
      this.answersBulk.push(item)
    },

    saveTestAnswersBulk(sourceTestId, homeworkId) {
      this.calcResults()
      ApiService.setHeader()
      ApiService.put('api/HomeworkAnswer/upsert-bulk', {
        Answers: this.answersBulk,
        HomeworkId: homeworkId,
        SourceTestId: sourceTestId
      })
          .then(() => {
            Swal.fire({
              toast: true,
              position: 'bottom',
              icon: 'success',
              title: 'Değişiklikler Uygulandı',
              showConfirmButton: false,
              timer: 1500
            })
            this.answersBulk = []
            this.getSourceTestAnswersFromApi(sourceTestId, homeworkId, false)
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    calcResults() {
      if (this.answersBulk.length > 0) {
        var model = {AnswerList: []}

        model.AnswerList = this.homeworkAnswers.map(function (s) {
          return {
            Id: s.Id,
            HomeworkId: s.HomeworkId,
            SourceTestId: s.SourceTestId,
            QuestionNumber: s.QuestionNumber,
            TestOptionTypeId: s.TestOptionTypeId
          }
        })

        this.answersBulk = this.answersBulk.map(function (s) {
          return {
            Id: s.Id,
            HomeworkId: s.HomeworkId,
            SourceTestId: s.SourceTestId,
            QuestionNumber: s.QuestionNumber,
            TestOptionTypeId: s.TestOptionTypeId
          }
        })

        for (var i = 0, l = model.AnswerList.length; i < l; i++) {
          for (var j = 0, ll = this.answersBulk.length; j < ll; j++) {
            if (model.AnswerList[i].QuestionNumber === this.answersBulk[j].QuestionNumber) {
              model.AnswerList.splice(i, 1, this.answersBulk[j])
              break
            }
          }
        }

        ApiService.setHeader()
        ApiService.post('api/HomeworkAnswer/calculateresult', model)
            .then((data) => {
              if (data.data != null) {
                this.homeworks.find((s) => s.HomeworkId === data.data.HomeworkId).SourceTestResult = data.data
              }
            })
            .catch(({response}) => {
              ApiService.showError(response)
            })
      }
    }
  }
}
</script>

<style>
.scroll {
  overflow-y: scroll;
}
</style>
